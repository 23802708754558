<template>
  <!-- We are adding bottom padding to avoid the list action being hidden by the chat bubble -->
  <div class="tw-pb-24">
    <PageHeader v-if="!forContact" title="Overzicht facturen" />

    <div v-if="!forContact" class="tw-my-6 tw-mx-auto">
      <FormulateForm
        v-model="filters"
        :key="searchKey"
        name="filterInvoices"
        debounce
        class="tw-px-3 tw-border-b"
      >
        <div class="tw-flex tw-flex-wrap tw-gap-4">
          <FormulateInput
            type="select"
            name="type"
            label="Documenttype"
            placeholder="Selecteer documenttype"
            :options="[
                { value: 1, label: 'Factuur' },
                { value: 2, label: 'Creditnota' },
            ]"
            :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
            :label-class="['tw-text-xs']"
            outer-class="tw-my-0"
          />
          <FormulateInput
            type="autocomplete"
            auto-complete-type="user-offices"
            :multiple="true"
            debounce
            name="offices"
            label="Kantoren"
            placeholder="Selecteer kantoren"
            :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
            :label-class="['tw-text-xs']"
            outer-class="tw-m-0"
            @input="loadJournals"
          />
          <FormulateInput
            type="multiselect"
            :multiple="true"
            name="journals"
            label="Dagboeken"
            placeholder="Selecteer dagboeken"
            :options="journalOptions"
            :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
            :label-class="['tw-text-xs']"
            outer-class="tw-m-0"
          />
          <FormulateInput
            type="select"
            name="is_paid"
            label="Betaald"
            :options="{
              '': 'Alle',
              true: 'Ja',
              false: 'Nee'
            }"
            :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
            :label-class="['tw-text-xs']"
            outer-class="tw-my-0"
          />

          <fieldset class="fieldset-split-range !tw-my-0">
            <legend class="!tw-text-xs !tw-mb-1">Factuurdatum</legend>
            <FormulateInput
              type="date"
              name="invoice_date_after"
              validation="bail|optional|date:YYYY-MM-DD"
              :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
              outer-class="tw-m-0"
            />
            <FormulateInput
              type="date"
              name="invoice_date_before"
              validation="bail|optional|date:YYYY-MM-DD"
              :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
              outer-class="tw-m-0"
            />
          </fieldset>

          <FormulateInput
            type="text"
            name="invoice_number"
            label="Factuurnummer"
            placeholder="Factuurnummer"
            :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
            outer-class="tw-my-0"
          />
          <FormulateInput
            type="select"
            name="financial_year"
            label="Boekjaar"
            placeholder="Selecteer boekjaar"
            :options="financialYears"
            :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
            :label-class="['tw-text-xs']"
            outer-class="tw-my-0"
          />
        </div>
        <div class="tw-flex tw-flex-wrap tw-gap-4 tw-mt-4">
          <FormulateInput
            type="select"
            name="property_status"
            label="Dossierstatus"
            placeholder="Selecteer dossierstatus"
            :options="PROPERTY_STATUSES"
            :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
            :label-class="['tw-text-xs']"
            outer-class="tw-my-0"
          />
          <FormulateInput
            type="select"
            name="transaction_type"
            label="Transactietype"
            placeholder="Selecteer transactietype"
            :options="PROPERTY_TRANSACTION_TYPES"
            :input-class="['tw-text-sm tw-border-none tw-h-8 tw-mt-0.5']"
            :label-class="['tw-text-xs']"
            outer-class="tw-my-0"
          />
        </div>

        <div class="tw-my-4 tw-text-sm tw-font-semibold tw-flex tw-flex-wrap tw-gap-4">
          <router-link
            :to="{ query: { filters: JSON.stringify(filters) } }"
            class="link hover:tw-text-opacity-80"
          >
            <i class="fas fa-search" /> Zoeken
          </router-link>
          <a
            class="link !tw-text-danger hover:!tw-text-opacity-80"
            @click="resetFilters"
          >
            <i class="fas fa-times" /> Filters wissen
          </a>
        </div>
      </FormulateForm>
    </div>

    <DataTable
      v-show="showDataTable"
      :loading="loading"
      :headers="headers"
      :infinite-scroll="true"
      v-bind="invoices"
      :class="forContact ? 'tw--m-5 tw-shadow-none' : ''"
      @changedOrder="changeOrder"
      @infiniteLoad="infiniteLoad"
    >
      <template #toolbar>
        <div class="tw-mb-2 tw-flex tw-justify-end">
          <button
            type="button"
            title="Exporteer de resultaten als csv"
            :disabled="exporting"
            class="action tw-bg-success tw-mr-0"
            @click="exportAsCsv"
          >
            <i
              :class="[
                'fas',
                exporting
                  ? 'fa-spinner-third fa-spin'
                  : 'fa-external-link'
              ]"
            />
            Exporteren
          </button>
        </div>
      </template>

      <template #item-invoice_number="{ item }">
        <button
          v-if="item.property_file || item.project_file"
          type="button"
          class="link"
          @click="downloadFile(item.id)"
        >
          {{ item.invoice_number }}
        </button>
      </template>

      <template #item-type_display="{ item }">
        <span>
            {{ item.type_display }}
            <Tooltip v-if="item.credit_note_reason" class="tw-inline-block tw-mr-0.5">
              <i class="fas fa-info-circle" />
              <template #popper>
                <div class="tw-text-center">
                  {{ item.credit_note_reason }}
                </div>
              </template>
            </Tooltip>
        </span>
      </template>

      <template #item-entity_reference="{ item }">
        <details v-if="item.entities.length > 1">
          <summary class="tw-cursor-pointer tw-italic">
            Bulkfactuur
          </summary>
          <EntityHoverable
            v-for="entity in item.entities"
            :value="entity"
            :include-address-in-link-text="true"
            :key="entity.id"
          />
        </details>
        <EntityHoverable
          v-else
          v-for="entity in item.entities"
          :value="entity"
          :include-address-in-link-text="true"
          :key="entity.id"
        />
      </template>
      <template #item-transaction_type="{ item }">
        <span v-if="item.entities.length === 1">{{ item.entities[0].transaction_type }}</span>
        <span v-else>-</span>
      </template>
      <template #item-status="{ item }">
        <span v-if="item.entities.length === 1">{{ item.entities[0].status }}</span>
        <span v-else>-</span>
      </template>
      <template #item-invoice_date="{ item }">
        {{ item.invoice_date | date-day }}
      </template>
      <template #item-invoice_amount_incl_vat="{ item }">
        {{ currency(item.invoice_amount_incl_vat) }}
      </template>
      <template #item-balance="{ item }">
        {{ currency(item.balance) }}
      </template>
      <template #item-payment_status="{ item }">
        <i
          :class="[
            'fas tw-text-base',
            PAYMENT_STATUSES[item.payment_status]
          ]"
        />
      </template>

      <template #item-transaction_id="{ item }">
        <a
          v-if="item.transaction_id"
          :href="`/transaction/view/${item.transaction_id}`"
          target="_blank"
          class="link tw-font-semibold"
        >
          <i class="far fa-link" />
        </a>
      </template>
    </DataTable>
  </div>
</template>

<script>
import merge from 'lodash/merge'
import { successModal, errorModal } from '@/modalMessages'
import { currency, exportAsCsvHelper } from '@/utils/helpers'
import { PROPERTY_STATUSES, PROPERTY_TRANSACTION_TYPES } from '@/forms/selectOptions'

import DataTable from '@/components/DataTable'
import PageHeader from '@/components/PageHeader.vue'
import EntityHoverable from '@/components/iam/EntityHoverable'
import { Tooltip } from 'floating-vue'

import { getJournals } from '@/services/journals'
import { getInvoices } from '@/services/invoices'
import { getFinancialYears } from '@/services/apiService'

const DEFAULT_FILTERS = {
  overview: true
}

export default {
  name: 'TransactionListInvoices',
  components: {
    DataTable,
    PageHeader,
    EntityHoverable,
    Tooltip
  },
  constants: {
    PROPERTY_STATUSES,
    PAYMENT_STATUSES: {
      refunded: 'fa-check-circle tw-text-success',
      unrefunded: 'fa-times-circle tw-text-danger',
      partially_refundend: 'fa-exclamation-circle tw-text-warning',
      paid: 'fa-check-circle tw-text-success',
      unpaid: 'fa-times-circle tw-text-danger',
      partially_paid: 'fa-exclamation-circle tw-text-warning'
    },
    PROPERTY_TRANSACTION_TYPES
  },
  data () {
    return {
      loading: false,
      searchKey: 'init',
      filters: { ...DEFAULT_FILTERS },
      invoices: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      showDataTable: false,
      exporting: false,
      journalOptions: [],
      financialYears: []
    }
  },
  computed: {
    forContact () {
      // Is this view being used for contacts? Headers should change and no filters https://dewaele.atlassian.net/browse/DEW-10181
      return this.$route.meta?.navigationGroupName === 'Contacts'
    },
    contactId () {
      // Should only be used when forContact is true
      return this.$route.params.id
    },

    headers () {
      return [
        { text: 'Factuurnummer', value: 'invoice_number', orderable: true },
        { text: 'Factuurdatum', value: 'invoice_date', orderable: true },
        { text: 'Type', value: 'type_display' },

        ...this.forContact
          ? []
          : [
            {
              text: 'Kantoor',
              value: 'office_reference',
              orderable: true,
              orderByKey: 'office_reference'
            }
          ],

        { text: 'Dagboek', value: 'journal', orderable: true, orderByKey: 'journal__name' },
        { text: 'Dossier', value: 'entity_reference', orderable: true },
        ...this.forContact
          ? []
          : [{ text: 'Transactietype', value: 'transaction_type' }],

        { text: 'Status', value: 'status' },
        { text: 'Klant', value: 'invoice_contact' },
        { text: 'Bedrag incl. btw', value: 'invoice_amount_incl_vat' },
        { text: 'Balans', value: 'balance' },
        { text: 'Betaald', value: 'payment_status' },
        { text: 'Transactie', value: 'transaction_id' }
      ]
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler (value) {
        if (this.forContact) return

        this.clearValues()

        if (!value.filters) {
          this.showDataTable = false
          return false
        }

        this.showDataTable = true
        const parsedFilters = JSON.parse(value.filters)
        const mergedFilters = merge(this.filters, parsedFilters)
        this.filters = Object.assign({}, this.filters, mergedFilters)

        this.queryInvoices()
      }
    }
  },
  created () {
    this.loadFinancialYears()

    if (this.forContact) {
      this.showDataTable = true
      this.queryInvoices({
        contact: this.contactId,
        ...DEFAULT_FILTERS
      })
    }
  },
  methods: {
    currency,
    parseParams ({ offices, ...payload }) {
      // Checklist is actually just parent category that isn't really used in the filtering.
      const params = { ...payload }
      params.offices = offices?.map(office => office.id) || []
      // Do not filter on is_paid if the '' option is selected
      if (!params.is_paid) delete params.is_paid
      return params
    },
    resetFilters () {
      this.$router.push({ query: {} }).catch(() => {})
    },
    clearValues () {
      this.searchKey = Math.random() // Reset form and it's values
      // set filters to default value
      this.filters = { ...DEFAULT_FILTERS }
      this.invoices = {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    },
    changeOrder (data) {
      this.invoices = {
        count: null,
        next: null,
        previous: null,
        results: []
      }
      this.filters = {
        ...this.filters,
        ...data.params
      }
      this.queryInvoices()
    },

    async loadFinancialYears () {
      const response = await getFinancialYears()
      const years = response.data?.results || []
      this.financialYears = years.map(({ id, year }) => {
        return { label: year, value: id }
      })
      return response
    },
    async loadInvoices (payload) {
      const response = await getInvoices(payload)
      return response.data
    },
    async queryInvoices (data = this.filters) {
      this.loading = true
      const params = this.parseParams(data)
      const invoices = await this.loadInvoices({ params })
      this.invoices = invoices
      this.loading = false
      return invoices
    },
    async infiniteLoad ($infinite, next) {
      try {
        if (!next) {
          // No more data and state is loaded
          if (this.invoices.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const invoices = await this.loadInvoices({ url: next })
        const results = [...this.invoices.results, ...invoices.results]
        this.invoices = { ...invoices, results }
        $infinite.loaded()
        return invoices
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },

    async loadJournals (offices = []) {
      this.journalOptions = []

      const params = {
        for_user: true,
        offices: offices.map(office => office.id)
      }
      const response = await getJournals(params)
      const journals = response.data?.results || []

      if (this.filters?.journals?.length) {
        // We only keep the journal selections that exist in the newly fetched journal options.
        const journalSelections = []
        journals.map(journal => {
          const strValue = journal.value.toString()
          const journalExists = this.filters.journals.includes(strValue)
          if (journalExists) journalSelections.push(strValue)
        })
        this.$set(this.filters, 'journals', journalSelections)
      } else this.$set(this.filters, 'journals', [])

      // journals are already in the Vue Formulate's required format
      this.journalOptions = journals
      return response
    },

    async downloadFile (invoiceId) {
      // We fetch the individual invoice, by it's id, to get the property URL, as the overview doesn't give us URLs
      const params = { ids: invoiceId }
      const { results } = (await getInvoices({ params })).data
      const fileUrl = (results[0]?.property_file || results[0]?.project_file)?.url
      const win = window.open(fileUrl, '_blank')
      if (!win) errorModal('Sta pop-ups toe om het document te zien.')
      return results
    },

    async exportAsCsv () {
      try {
        this.exporting = true
        const search_params = this.parseParams(this.filters)
        const pollResult = await exportAsCsvHelper({ type: 'invoices', search_params })
        successModal(`Succesvol ${this.invoices.count} records geëxporteerd.`)
        return pollResult
      } catch (error) {
        errorModal('Kan facturen niet exporteren, probeer het opnieuw.')
      } finally {
        this.exporting = false
      }
    }
  }
}
</script>
